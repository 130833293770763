<template>
  <b-container class="p-0 dashboard-page" fluid>
    <container-header/>
    <b-row class="d-flex justify-content-center">
      <b-col lg="7" md="7" sm="11" xl="5">
        <get-number-screen></get-number-screen>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import ContainerHeader from '@/components/App/UI/ContainerHeader.vue';
import GetNumberScreen from '@/components/App/User/GetNumber/GetNumberScreen.vue';

export default {
  components: {
    ContainerHeader,
    GetNumberScreen,
  },
};
</script>
